import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react'
import { Paper, Box, Typography, IconButton, Tooltip, Snackbar, Alert } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import api from '../apis/api.js';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Delete from '@mui/icons-material/Delete.js';
import BackdropLoader from './common/BackdropLoader.jsx';
import { GoalsContext } from '../context/GoalsContext.js';
import { CheckInContext } from '../context/CheckInContexts.js';
import { SnackbarContext } from '../context/SnackbarContext.js';

const CheckIns = (props) => {

    const { goals, getGoals } = useContext(GoalsContext)
    const { checkIns, getCheckIns } = useContext(CheckInContext)
    const { openAlert } = useContext(SnackbarContext)
    const [rowsData, setRowsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [openLoader, setOpenLoader] = useState(false);
    const [loaderSkeleton, setLoaderSkeleton] = useState(true);
    const [plusIconDisabled, setPlusIconDisabled] = useState(false)

    const getCheckInData = useCallback(async () => {
        setIsLoading(true);
        const checkinPlayer = checkIns?.filter((checkin) => {
            return checkin.player_id === props.playerId && checkin.status !== 'archived'
        })

        const goalsPlayer = goals?.filter(goal => goal.people_builder.player.id === props.playerId && goal.goal_status !== 'archived' && goal.people_builder.year === props.peopleBuilder.year)
        const goalsPBIds = new Set(Array.from(goalsPlayer?.map(goal => goal.people_builder_id)))
        let checkinPlayerFiltered

        checkinPlayerFiltered = checkinPlayer?.filter((checkin) => {
            const checkPbId = checkin.check_in_goals.filter(goal => goalsPBIds.has(goal.goal.people_builder_id))
            return checkin.check_in_type === 'monthly-review' && checkPbId.length
        })

        const checkinData = checkinPlayerFiltered?.map(checkin => ({
            id: checkin.id,
            month: checkin.monthly_ref,
            checkinDate: checkin.check_in_date.split('T')[0],
            meeting: checkin.meeting_date ? 'Yes' : 'No',
            goal_comments: checkin.goal_comments,
        }))

        checkinData?.sort((a, b) => new Date(b.checkinDate) - new Date(a.checkinDate))

        setRowsData(checkinData);
        setIsLoading(false);
    }, [props.playerId, props.title, checkIns])

    const columns = useMemo(() => [
        {
            field: 'month',
            headerName: 'Month',
            headerClassName: 'checkin-header',
            width: 300,
        },
        {
            field: 'checkinDate',
            headerName: 'Check-In Date',
            headerClassName: 'checkin-header',
            width: 400,
        },
        {
            field: 'meeting',
            headerName: '1:1 Meeting?',
            headerClassName: 'checkin-header',
            width: 300,
            flex: 1
        },
        {
            field: 'actions',
            headerName: 'Actions',
            headerClassName: 'checkin-header',
            width: 100,
            renderCell: (params) => {
                return (
                    <Tooltip title="Delete Check-In">
                        <IconButton onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(params.id)
                        }}>
                            <Delete />
                        </IconButton>
                    </Tooltip >
                )
            }
        }
    ], [])

    const handleDelete = useCallback(async (id) => {
        setOpenLoader(true)
        try {
            const response = await api.delete(`/checkins/${id}`);

            if (response.status === 200) {
                setRowsData(prevData => prevData.filter(row => row.id !== id))
                getCheckInData()
                setOpenLoader(false)
                await getGoals()
                hasGoals()
                getCheckIns()

            }

            openAlert('Check-in deleted successfully', 'success')
        } catch (error) {
            console.error('Error deleting check-in:', error)
            openAlert('Error deleting check-in', 'error')
            setOpenLoader(false)
        }
    }, [getCheckInData])

    const openCheckInForm = useCallback((id) => {
        const userGoals = goals.filter(goal => goal.people_builder.player.id === id && goal.people_builder_id === props.peopleBuilder.id)
        let userGoalsStatus = new Set()

        if (userGoals.length) {
            userGoals.map(goal => userGoalsStatus.add(goal.goal_status))
        }

        if (userGoalsStatus.has('Draft Goal') || userGoalsStatus.has('Approval') || userGoalsStatus.has('Needs Revision')) {
            openAlert('Goals should be Active', 'error')
            return
        } else if (!userGoalsStatus.size) {
            openAlert('All goals need to be Active', 'error')
            return
        }
        navigate(`/new-check-in/${id}/${props.peopleBuilder.id}`);
    }, [goals])

    const hasGoals = useCallback(() => {
        const userGoals = goals.filter(goal => goal.people_builder.player.id === props.playerId && goal.goal_status !== 'archived' && goal.people_builder.year === props.peopleBuilder.year)
        const userStatus = userGoals.map(goal => goal.goal_status)

        if (!userGoals.length) {
            setPlusIconDisabled(true)
        } else if (!userStatus.every(status => status === 'Active')) {
            setPlusIconDisabled(true)
        } else {
            setPlusIconDisabled(false)
        }
    }, [goals])

    const openRefCheckInForm = useCallback((id) => {
        navigate(`/check-in/${props.playerId}/${id}/${props.peopleBuilder.id}`);
    }, [navigate])

    useEffect(() => {
        getCheckIns()
        getCheckInData()
        hasGoals()
        setTimeout(() => {
            setLoaderSkeleton(false)
        }, 2000);
    }, [])

    useEffect(() => {
        getCheckInData()
    }, [checkIns])

    useEffect(() => {
        hasGoals()
    }, [goals, getGoals])

    return (
        <React.Fragment>
            <BackdropLoader openBackdrop={openLoader} />
            <Paper sx={{ p: 2, m: 2 }}>
                <Typography
                    variant="h6"
                    textAlign='center'
                    gutterBottom
                    component="div"
                >
                    Monthly 1:1
                </Typography>
                <DataGrid
                    rows={rowsData}
                    columns={columns}
                    disableColumnResize={true}
                    onRowClick={(params) => openRefCheckInForm(params.row.id)}
                    rowSelection={false}
                    loading={loaderSkeleton}
                    localeText={{ noRowsLabel: "No Reviews" }}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        }
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 4,
                                page: 0
                            },
                        },
                    }}
                    pageSizeOptions={[4]}
                    sx={{
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                            backgroundColor: 'none'
                        },
                        '& .MuiDataGrid-cell:hover': {
                            backgroundColor: 'none',
                        },
                        '& .checkin-header': {
                            backgroundColor: '#ccc',
                        },
                        '& .MuiDataGrid-row': {
                            cursor: 'pointer',
                        },
                        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                    }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    {plusIconDisabled ? (
                        <Tooltip title="All goals need to be Active">
                            <Box >
                                <IconButton disabled onClick={() => openCheckInForm(props.playerId)}>
                                    <AddCircleOutlineIcon sx={{ cursor: 'pointer' }} />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Add Check-In">
                            <IconButton onClick={() => openCheckInForm(props.playerId)}>
                                <AddCircleOutlineIcon sx={{ cursor: 'pointer' }} />
                            </IconButton>
                        </Tooltip>
                    )}

                </Box>
            </Paper>
        </React.Fragment >
    )
}

export default React.memo(CheckIns)