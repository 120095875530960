import {
    Button, Dialog, IconButton, Stack, DialogTitle, DialogContent, DialogActions, Card,
    Typography, Switch, TextField, TableContainer, Box, Table, TableHead, TableRow,
    TableCell, TableBody, Tooltip, ImageListItem, CardContent, Avatar
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../apis/api.js';
import moment from 'moment';
import BackdropLoader from './common/BackdropLoader.jsx'
import { green } from '@mui/material/colors';
import { alpha, styled } from '@mui/material/styles';
import { SnackbarContext } from '../context/SnackbarContext.js';
import CommentsTimeline from '../components/common/CommentsTimeline.jsx'

const AdditionalInformation = (props) => {
    const { peopleBuilder, open } = props
    const [openDialog, setOpenDialog] = useState(open ?? false)
    const [checked, setChecked] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null)
    const [descriptionFile, setDescriptionFile] = useState('')
    const [urlFile, setUrlFile] = useState('')
    const [files, setFiles] = useState([])
    const [textMeeting, setTextMeeting] = useState(null)
    const [colorIconMeeting, setColorIconMeeting] = useState(null)
    const [descriptionRef, setDescriptionRef] = useState(null);
    const [urlRef, setUrlRef] = useState(null);
    const [additionalInformation, setAdditionalInformation] = useState([]);
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [comment, setComment] = useState('')
    const [comments, setComments] = useState([])
    const { openAlert } = useContext(SnackbarContext)

    const handleSwitchChange = (event) => {
        setChecked(event.target.checked)
        setSelectedDate(null)
    }

    const handleDeleteFile = (id) => {
        const newFiles = files.filter(file => file.id !== id)
        setFiles(newFiles)
    }

    const handleClose = () => {
        setOpenDialog(false)
    }

    const createFiles = () => {

        if (!descriptionFile.trim()) {
            descriptionRef?.focus();
            return;
        }

        if (!urlFile.trim()) {
            urlRef?.focus();
            return;
        }

        setFiles(prevState => ([...prevState, {
            id: files.length + 1,
            description: descriptionFile,
            url: urlFile,
            uploaded: new Date().toLocaleDateString('en-ca')
        }]))
        setDescriptionFile('')
        setUrlFile('')
    }

    const hasEmptyFields = (data) => {
        if (!comments.length) {
            openAlert('Comments field cannot be empty', 'error')
            return true
        }
    }

    const handleCreateAddInformation = async (id) => {
        const data = {
            player_people_builder_id: peopleBuilder.id,
            had_meeting: selectedDate ? true : false,
            meeting_date: selectedDate,
            comments: JSON.stringify(comments),
            coach_id: peopleBuilder.coach.id,
            files: JSON.stringify(files)
        }

        if (hasEmptyFields(data)) {
            return
        }

        setOpenDialog(false)

        try {
            if (!additionalInformation[0]?.id) {
                const response = await api.post('/additional-information', data)
                if (response.status === 200) {
                    openAlert('Additional Information created successfully', 'success')
                    setOpenBackdrop(false)
                    getAddInformation()
                } else {
                    openAlert(`Oops! Something went wrong. Status: ${response.status}`, 'error')
                    setOpenBackdrop(false)
                }
            } else {
                updateAddInformation(additionalInformation[0]?.id)
            }
            getAddInformation()
        } catch (error) {
            console.log(error)
            openAlert(`Oops! Something went wrong. Status: ${error.status}`, 'error')
        }
    }

    const updateAddInformation = async (id) => {
        setOpenBackdrop(true)
        const data = {
            player_people_builder_id: peopleBuilder.id,
            had_meeting: selectedDate ? true : false,
            meeting_date: selectedDate,
            comments: JSON.stringify(comments),
            coach_id: peopleBuilder.coach.id,
            files: JSON.stringify(files)
        }
        try {
            const response = await api.post(`/additional-information/${id}`, data)

            if (response.status === 200) {
                openAlert('Additional Information was updated successfully', 'success')
                setOpenBackdrop(false)
                getAddInformation()
            } else {
                openAlert(`Something was wrong. Status: ${response.status}`, 'error')
                setOpenBackdrop(false)
            }

        } catch (error) {
            console.log(error)
            setOpenBackdrop(false)
            openAlert(`Something was wrong. Status: ${error.status}`, 'error')
        }

    }

    const GreenSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: green[600],
            '&:hover': {
                backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: green[600],
        },
    }))

    const getAddInformation = async () => {

        try {
            const response = await api.get('/additional-information')
            const addInfo = response.data.filter(info => info.player_people_builder_id === peopleBuilder.id)

            if (addInfo.length) {
                setAdditionalInformation(addInfo)
                setComments(JSON.parse(addInfo[0].comments))
                setFiles(JSON.parse(addInfo[0].files))
                setChecked(addInfo[0].had_meeting)
                setSelectedDate(moment(addInfo[0].meeting_date?.split('T')[0]))
                setColorIconMeeting(green[600])
                addInfo[0].had_meeting ? setTextMeeting(`Meeting 1:1 on ${addInfo[0].meeting_date?.split('T')[0]}`) : setTextMeeting('No Meeting 1:1')
            } else {
                setColorIconMeeting('grey')
                setTextMeeting('No Meeting 1:1')
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAddInformation()
    }, [])

    return (
        <React.Fragment>
            <BackdropLoader openBackdrop={openBackdrop} />
            <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'right' }}>
                <Tooltip title='Additional Information' placement='top'>
                    <IconButton onClick={() => setOpenDialog(true)}>
                        <InfoIcon sx={{ color: colorIconMeeting, fontSize: 18 }} />
                    </IconButton>
                </Tooltip>
                <Button onClick={() => setOpenDialog(true)} sx={{ fontSize: 10, color: colorIconMeeting }} >
                    {textMeeting}
                </Button>
            </Stack>

            <Dialog
                open={openDialog}
                onClose={handleClose}
                maxWidth="xl"
                fullWidth={true}
                sx={{
                    maxWidth: '100vw',
                    maxHeight: '100vh',
                    overflowY: 'auto'
                }}
            >

                <DialogTitle id="alert-dialog-title">
                    <Stack direction='row' sx={{ alignItems: 'center' }}>
                        <ImageListItem sx={{ width: '3rem' }}>
                            <img src="https://iili.io/JazaDEg.jpg" alt="" />
                        </ImageListItem>
                        <Typography variant="h5" color="initial">People Builder: End of Goal Settings Phase</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Box elevation={2} sx={{ mt: 2, mb: 2, p: 2, minHeight: 90 }}>
                        <Stack direction='row' spacing={2} alignItems='center'>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} variant='span'>Did Coach and Player have a 1:1 meeting during the Goals setting process?</Typography>
                                <GreenSwitch
                                    color="default"
                                    checked={checked}
                                    onChange={handleSwitchChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Stack>
                            <LocalizationProvider
                                dateAdapter={AdapterMoment}
                            >
                                <DatePicker
                                    label="Meeting Date"
                                    sx={{ visibility: checked ? 'visible' : 'hidden', width: '50%' }}
                                    value={selectedDate}
                                    onChange={(newValue) => {
                                        setSelectedDate(newValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    format='YYYY-MM-DD'
                                />
                            </LocalizationProvider>
                        </Stack>
                        <CommentsTimeline
                            title='How did the meeting go?'
                            setComment={setComment}
                            comment={comment}
                            setComments={setComments}
                            comments={comments}
                        />
                        <Card sx={{ mt: 4, p: 2 }}>
                            <CardContent>
                                <Stack direction='row' spacing={2}>
                                    <TextField
                                        variant="standard"
                                        id="description-file"
                                        label="Description"
                                        sx={{ width: '40%' }}
                                        value={descriptionFile}
                                        onChange={(e) => {
                                            setDescriptionFile(e.target.value)
                                        }}
                                        inputRef={setDescriptionRef}
                                    />
                                    <TextField
                                        variant="standard"
                                        id="url-file"
                                        label="URL"
                                        fullWidth
                                        value={urlFile}
                                        onChange={(e) => {
                                            setUrlFile(e.target.value)
                                        }}
                                        inputRef={setUrlRef}
                                    />
                                    <Button onClick={createFiles} variant='outlined'>Upload</Button>
                                </Stack>
                                {files.length !== 0 && (
                                    <TableContainer sx={{ mt: 6 }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>File</TableCell>
                                                    <TableCell>Uploaded At</TableCell>
                                                    <TableCell>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {files.map((file, index) => (
                                                    <TableRow
                                                        onClick={() => {
                                                            window.open(file.url, '_blank')
                                                        }}
                                                        key={index}
                                                        hover
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell>{file.description}</TableCell>
                                                        <TableCell>{file.uploaded}</TableCell>
                                                        <TableCell>
                                                            <Tooltip title='Delete File'>
                                                                <IconButton
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        handleDeleteFile(file.id)
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </CardContent>
                        </Card>
                        <Stack direction='row' sx={{ mt: 2 }} alignItems='center'>
                            <Avatar src={peopleBuilder?.player?.photo_url ?? ''} />
                            <Typography sx={{ ml: 1 }} variant='body1'>{peopleBuilder?.player?.full_name ?? ''}</Typography>
                        </Stack>
                        <Stack direction='row' sx={{ mt: 2 }} alignItems='center'>
                            <Avatar src={peopleBuilder?.coach?.photo_url ?? ''} />
                            <Typography sx={{ ml: 1 }}>{peopleBuilder?.coach?.full_name}</Typography>
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='error' onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button variant='contained' color='success' onClick={() => handleCreateAddInformation(peopleBuilder?.id)}>Save</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default AdditionalInformation