import React, { useEffect } from 'react'
import { Box, Button, Stack, TextField } from '@mui/material'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

const schema = z.object({
    title: z.string().nonempty('Title is required'),
    message: z.string()
})

const SlackMessageForm = ({ sendSlackMessage, setSlackObject, setPbData, slackObject }) => {
    const { register, handleSubmit } = useForm({
        resolver: zodResolver(schema)
    })

    const handleTestSlackMessage = async (data) => {
        sendSlackMessage(data)
    }
    const handleChange = (e) => {

        setSlackObject(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    useEffect(() => {
        setPbData(prev => ({
            ...prev,
            slack_message: JSON.stringify(slackObject)
        }))
    }, [slackObject])

    return (
        <form onSubmit={handleSubmit(handleTestSlackMessage)}>
            <Stack direction='row' spacing={2}>
                <TextField
                    sx={{ width: '30%' }}
                    {...register('title')}
                    label="Title"
                    variant="standard"
                    onChange={handleChange}
                />
                <TextField
                    sx={{ width: '70%' }}
                    {...register('message')}
                    label="Message"
                    variant="standard"
                    onChange={handleChange}
                />
            </Stack>
            <Box m={2}>
                <Button type='submit' variant="contained">Send test Slack message</Button>
            </Box>
        </form>
    )
}

export default SlackMessageForm