import React, { useState } from 'react'
import { Dialog, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { createTitle } from '../apis/titlesAPI.js';

const NewTitle = ({ handleCloseDialog, bringTitles, openDialogNewTitle, setSelectedFields, setLoading, openAlert }) => {
    const [newTitle, setNewTitle] = useState('')

    const createNewTitle = async (newTitle) => {
        handleCloseDialog()
        setLoading(true)
        const response = await createTitle(newTitle)
        setSelectedFields(prev => ({ ...prev, title: newTitle, titleId: response.data.id  }))
        await bringTitles()
        openAlert(response.message, response && response.status === 200 ? 'success' : 'error')
        setLoading(false)
    }

    return (
        <Dialog
            open={openDialogNewTitle}
            maxWidth='xl'
        >
            <DialogContent>
                <TextField
                    label="Enter new Title"
                    onChange={(e) => {
                        setNewTitle(e.target.value)
                    }}
                />
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            createNewTitle(newTitle)
                        }}
                        variant="outlined"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default NewTitle