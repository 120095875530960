import api from "./api.js";

export const getSignature = () => `
    <b>ETRO Construction</b>
    <br>
    <br>
    <span>M: (403) 497-6890</span>
    <br>
    <a href="https://www.google.com/maps/place/4727+Hastings+St,+Burnaby,+BC+V5C+2K8,+Canada/@49.2812857,-122.996154,17z/data=!3m1!4b1!4m6!3m5!1s0x548670b45c7abb1d:0x28f24f3c9c6c37ea!8m2!3d49.2812857!4d-122.996154!16s%2Fg%2F11b8v7pc3n?entry=ttu&g_ep=EgoyMDI0MTIxMC4wIKXMDSoASAFQAw%3D%3D">    
    4727 Hastings Street, Burnaby, BC V5C 2K8</a>
    <br>
    <a href="https://www.etroconstruction.com/">ETRO Construction</a>
`;

/**
 * 1. When all goals are set to ‘Active’: email to player
 * @function - Sends an email to a player requesting additional information for their goals.
 *
 * @param {Object} data - The data required to send the email.
 * @param {Object} data.player - The player to whom the email will be sent.
 * @param {number} data.year - The year for which the goals are active.
 * @returns {Promise<Object>} The response from the API call.
 * @throws Will log and return an error if the API call fails.
 */
export const sendEmailsAddittionalInfoToPlayer = async (data) => {
    const environment = process.env.NODE_ENV || 'development';
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    let content = `
                <div>
                    <span>Hello ${data.player.first_name} </span>
                </div>
                <br>
                <div>
                    <span>Now that all of <b>your</b> goals for  ${data.year} are active, you will need to provide some additional information. </span>
                    <br>
                    <br>
                    <span> Please visit the PeopleBuilder portal in 
                    <a href="${'https://dev-lifecycle.etroconstruction.dev/personal-people-builder'}/${data.player.id}">ETRO Lifecycle</a> at your earliest convenience to fill out the <b>Additional Information</b> form. </span>
                </div>
                <br>
                <br>
                <i> If you have any issues accessing the portal, please <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=support@etroconstruction.zohodesk.com">Contact IT</a>
                </i>
                <br>
                <br>
                ${getSignature()}

                `
    const emailObject = {
        to: environment === 'production' ? data.player.email : loggedInUser.email,
        subject: `Additional information regarding ${data.player.first_name}’s ${data.year} goals is requested`,
        content: content
    }

    try {
        return await api.post('/send-emails', emailObject)
    } catch (error) {
        console.log(error)
        return error
    }
}

/**
 * @function - 2. Sends an email to the coach requesting additional information regarding a player's goals.
 *
 * @param {Object} data - The data required to send the email.
 * @param {Object} data.coach - The coach's information.
 * @param {Object} data.player - The player's information.
 * @param {number} data.year - The year of the goals.
 * @returns {Promise<Object>} The response from the API or an error object.
 */
export const sendEmailsAddittionalInfoToCoach = async (data) => {
    const environment = process.env.NODE_ENV || 'development';
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    let content = `
                <div>
                    <span>Hello ${data.coach.first_name} </span>
                </div>
                <br>
                <div>
                    <span>Now that all of <b>${data.player.first_name}</b>  ’s goals for  ${data.year} are active, you will both need to provide some additional information. </span>
                    <br>
                    <br>
                    <span> Please visit the PeopleBuilder portal in 
                    <a href="${'https://dev-lifecycle.etroconstruction.dev/team-people-builder'}/${data.coach.id}">ETRO Lifecycle</a> at your earliest convenience to fill out the <b>Additional Information</b> form. </span>
                </div>
                <br>
                <br>
                <i> If you have any issues accessing the portal, please <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=support@etroconstruction.zohodesk.com">Contact IT</a>
                </i>
                <br>
                <br>
                ${getSignature()}

                `
    const emailObject = {
        to: environment === 'production' ? data.coach.email : loggedInUser.email,
        subject: `Additional information regarding ${data.coach.full_name}’s ${data.year} goals is requested`,
        content: content
    }

    try {
        return await api.post('/send-emails', emailObject)
    } catch (error) {
        console.log(error)
        return error
    }
}

export const sendEmailsGoalReadyForApproval = async (emailData) => {
    const { user } = emailData
    const environment = process.env.NODE_ENV || 'development';
    const loggedInUser = JSON.parse(localStorage.getItem('user'));

    let content = `
                <div>
                    <span>Hello ${user.player.coach} </span>
                </div>
                <br>
                <div>
                    <span>Your player <b>${user.player.full_name}</b>  has submitted the ${emailData.goal_term} goal <b>${emailData.goal_name}</b> for your approval! </span>
                    <br>
                    <br>
                    <span> Please visit the PeopleBuilder portal in 
                    <a href="${'https://dev-lifecycle.etroconstruction.dev/team-people-builder'}/${user.coach.id}">ETRO Lifecycle</a> at your earliest convenience to evaluate this goal. </span>
                </div>
                <br>
                <br>
                <i> If you have any issues accessing the portal, please <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=support@etroconstruction.zohodesk.com">Contact IT</a>
                </i>
                <br>
                <br>
                ${getSignature()}

                `
    const emailObject = {
        to: environment === 'production' ? user.coach.email : loggedInUser.email,
        subject: `${user.player.first_name}’s ${emailData.goal_term} goal is ready for review`,
        content: content
    }

    try {
        return await api.post('/send-emails', emailObject)
    } catch (error) {
        console.log(error)
        return error
    }
}

/**
 * @function - 3. Sends an email notification to a coach indicating that a player's goals are ready for approval.
 *
 * @param {Object} emailData - The data required to send the email.
 * @param {Object} emailData.coach - The coach's information.
 * @param {Object} emailData.player - The player's information.
 * @param {number} emailData.year - The year of the goals submission.
 * @returns {Promise<Object>} The response from the API call or an error object if the call fails.
 */
export const readyForApproval = async (emailData) => {
    const environment = process.env.NODE_ENV || 'development';
    const loggedInUser = JSON.parse(localStorage.getItem('user'));

    let content = `
                <div>
                    <span>Hello ${emailData.coach.first_name} </span>
                </div>
                <br>
                <div>
                    <span>Your player <b>${emailData.player.full_name}</b> has submitted the ${emailData.year} goals for your approval! </span>
                    <br>
                    <br>
                    <span> Please visit the PeopleBuilder portal in 
                    <a href="${'https://dev-lifecycle.etroconstruction.dev/team-people-builder'}/${emailData.coach.id}">ETRO Lifecycle</a> at your earliest convenience to review these goals. </span>
                </div>
                <br>
                <br>
                <i> If you have any issues accessing the portal, please <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=support@etroconstruction.zohodesk.com">Contact IT</a>
                </i>
                <br>
                <br>
                ${getSignature()}

                `
    const emailObject = {
        to: environment === 'production' ? emailData.coach.email : loggedInUser.email,
        subject: `${emailData.player.first_name}’s ${emailData.year} goals are ready for review`,
        content: content
    }

    try {
        return await api.post('/send-emails', emailObject)
    } catch (error) {
        console.log(error)
        return error
    }
}

/**
 * @function - 4. When the coach saves a goal with status  “Needs Revision.
 *
 * @param {Object} emailData - The data required to send the email.
 * @param {Object} emailData.coach - The coach's information.
 * @param {Object} emailData.player - The player's information.
 * @param {number} emailData.year - The year of the goals submission.
 * @returns {Promise<Object>} The response from the API call or an error object if the call fails.
 */
export const sendEmailsGoalNeedsRevision = async (emailData) => {
    const { user } = emailData
    const environment = process.env.NODE_ENV || 'development';
    const loggedInUser = JSON.parse(localStorage.getItem('user'));

    let content = `
                <div>
                    <span>Hello ${user.player.first_name} </span>
                </div>
                <br>
                <div>
                    <span>Your coach ${user.coach.first_name} has flagged the following goal(s) for revision:</span>
                    <br>
                    <br>
                    ${emailData.goal_term}: ${emailData.goal_name}
                    <br>
                    <br>
                    <span> Please visit the PeopleBuilder portal in 
                        <a href="${'https://dev-lifecycle.etroconstruction.dev/personal-people-builder'}/${user.player.id}">ETRO Lifecycle</a> 
                        at your earliest convenience to see your coach's comments and revise these goals accordingly. 
                    </span>
                </div>
                <br>
                <br>
                <i> If you have any issues accessing the portal, please <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=support@etroconstruction.zohodesk.com">Contact IT</a>
                </i>
                <br>
                <br>
                ${getSignature()}
                `
    const emailObject = {
        to: environment === 'production' ? user.player.email : loggedInUser.email,
        subject: `${emailData.goal_name} goal needs for revision`,
        content: content
    }

    try {
        return await api.post('/send-emails', emailObject)
    } catch (error) {
        console.log(error)
        return error
    }
}

export const sendGenericEmailsGoalNeedsRevision = async (emailData) => {
    const { user } = emailData
    const environment = process.env.NODE_ENV || 'development';
    const loggedInUser = JSON.parse(localStorage.getItem('user'));

    let content = `
                <div>
                    <span>Hello ${user.player.first_name} </span>
                </div>
                <br>
                <div>
                    <span>Your coach ${user.coach.first_name} has flagged goal(s) for revision</span>
                    <br>
                    <br>
                    <span> Please visit the PeopleBuilder portal in 
                        <a href="${'https://dev-lifecycle.etroconstruction.dev/personal-people-builder'}/${user.player.id}">ETRO Lifecycle</a> 
                        at your earliest convenience to see your coach's comments and revise these goals accordingly. 
                    </span>
                </div>
                <br>
                <br>
                <i> If you have any issues accessing the portal, please <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=support@etroconstruction.zohodesk.com">Contact IT</a>
                </i>
                <br>
                <br>
                ${getSignature()}
                `
    const emailObject = {
        to: environment === 'production' ? user.player.email : loggedInUser.email,
        subject: `Goals needs for revision`,
        content: content
    }

    try {
        return await api.post('/send-emails', emailObject)
    } catch (error) {
        console.log(error)
        return error
    }
}