import React, { useEffect, useState, useContext } from 'react'
import { Stack, Button, Typography, Tooltip, Box, CircularProgress } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import { readyForApproval } from '../apis/sendEmailsAPI'
import { SnackbarContext } from '../context/SnackbarContext';

const NotifyCoachReadyForApproval = ({ peopleBuilder }) => {
    const { openAlert } = useContext(SnackbarContext)
    const [loading, setLoading] = useState(false)
    const [coachNotified, setCoachNotified] = useState('')

    const handleClick = async () => {
        setLoading(true)
        const messageResponse = await readyForApproval(peopleBuilder)
        const date = new Date();
        const formattedDate = date.toLocaleDateString('en-ca', { year: 'numeric', month: '2-digit', day: '2-digit' });
        const formattedTime = date.toLocaleTimeString('en-ca', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
        const currentDate = `${formattedDate} at ${formattedTime}`; 

        if (messageResponse.status === 200) {
            setCoachNotified(currentDate)
            localStorage.setItem('coachNotified', currentDate)
            openAlert('Coach has been notified', 'success')
        } else {
            openAlert('Oops! Something went wrong', 'error')
        }
        setLoading(false)
    }

    useEffect(() => {
        setCoachNotified(localStorage.getItem('coachNotified'))
    }, [coachNotified])

    return (
        <Box sx={{ position: 'relative' }}>
            <Stack direction='row' sx={{ justifyContent: 'right' }}>
                <Stack alignItems='center'>
                    <Tooltip title='Send notification to coach about goals ready for approval' placement='top'>
                        <Button onClick={handleClick} disabled={loading} sx={{ fontSize: 10 }} >
                            <EmailIcon sx={{ fontSize: 18, marginRight: 1 }} />
                            Notify coach
                        </Button>
                    </Tooltip>
                    <Typography sx={{ fontSize: '10px' , color: 'darkgray'  }} variant="subtitle2" color="initial">{coachNotified ? `Notified on ${coachNotified}` : ''}</Typography>
                </Stack>
            </Stack >
            {loading && (
                <CircularProgress
                    size={24}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px'
                    }}
                />
            )}
        </Box>
    )
}

export default NotifyCoachReadyForApproval