import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent, { timelineContentClasses } from '@mui/lab/TimelineContent';
import { Typography, TextField, Avatar, Paper, Button, Stack, Snackbar, Alert } from '@mui/material';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { useState, useCallback } from 'react'
import moment from 'moment'

export default function CommentsTimeline(props) {
    const { setComment, comment, comments, setComments, handleCommentsChange, goalSelectedToComment, title } = props
    const [textfieldHelpText, setTextfieldHelpText] = useState('')
    const [errorTextfield, setErrorTextfield] = useState(false)
    const user = localStorage.getItem('user')
    const [loggedUser] = useState(JSON.parse(user))

    const handleAddComment = useCallback(() => {
        const addComments = [...comments ?? [], {
            comment: comment,
            commentDate: moment().format('MMM DD, YYYY [at] h:mm a'),
            name: loggedUser.full_name,
            avatar: loggedUser.photo_url
        }]
        setComments(addComments)
        handleCommentsChange ? handleCommentsChange(goalSelectedToComment?.id, addComments) : ''
        setComment('')
    })

    const handleClickAddComment = () => {
        if (comment === '') {
            setTextfieldHelpText(`Comment should not be empty`)
            setErrorTextfield(true)
            return
        }
        // updateGoalComments()
        handleAddComment()

    }

    return (
        <>
            <Timeline
                style={{ marginLeft: '0px', marginRight: '0px', padding: '0px' }}
                position='left'
                sx={{
                    [`& .${timelineContentClasses.root}`]: {
                        flex: 0.2,
                    },
                }}
            >
                {comments?.map((item, index) => (

                    <TimelineItem key={index} sx={{ width: '100%' }} >
                        <TimelineOppositeContent
                            sx={{
                                m: 'auto 0',
                                overflowWrap: 'anywhere',
                            }}
                            align="left"
                            variant="body2"
                            color="text.secondary"
                        >
                            {item.comment}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <Avatar src={item.avatar} />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <Stack sx={{ width: '20%', justifyContent: 'center' }}>
                            <TimelineContent style={{ paddingLeft: 0 }} sx={{ py: '12px', px: 2, textAlign: 'center' }}>
                                <Typography>{item.name}</Typography>
                                <Typography style={{ paddingLeft: 0 }} sx={{ fontSize: '11px' }} color={'text.secondary'} >{item.commentDate}</Typography>
                            </TimelineContent>
                        </Stack>
                    </TimelineItem>
                ))}
            </Timeline >
            <Stack direction='row' spacing={2}>
                <TextField
                    sx={{ marginBottom: 5, width: '80%' }}
                    id="outlined-multiline-static"
                    label={title ?? 'Comment'}
                    multiline
                    rows={2}
                    value={comment}
                    variant='outlined'
                    onChange={(e) => {
                        setTextfieldHelpText('')
                        setErrorTextfield(false)
                        setComment(e.target.value)
                    }}
                    helperText={textfieldHelpText}
                    error={errorTextfield}

                />
                <Button
                    variant='outlined'
                    sx={{ width: '20%' }}
                    onClick={handleClickAddComment}
                >
                    Add Comment
                </Button>
            </Stack>
        </>
    );
}
