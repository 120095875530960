import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
    Divider, ImageListItem, ImageList, Button, Paper, Card, CardContent, Container,
    FormGroup, Stack, TextField, Autocomplete, Switch, Typography, Grid, useMediaQuery,
    Chip, Snackbar, Alert, Box, Tooltip
} from '@mui/material';
import GoalSteps from './GoalSteps';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import api from '../apis/api';
import { useParams } from 'react-router-dom';
import BackdropLoader from './common/BackdropLoader.jsx'
import { useNavigate, use } from 'react-router-dom';
import moment from 'moment';
import CommentsTimeline from './common/CommentsTimeline.jsx';
import SideBarOnGoalForm from './SideBarOnGoalForm.jsx';
import { GoalsContext } from '../context/GoalsContext.js';
import { SnackbarContext } from '../context/SnackbarContext.js';
import { LoaderContext } from '../context/LoaderContext.js';
import { getGoalsAPI, createGoalAPI, updateGoalAPI } from '../apis/goalsAPI.js';
import { sendEmailsAddittionalInfoToPlayer, sendEmailsAddittionalInfoToCoach, sendEmailsGoalNeedsRevision } from '../apis/sendEmailsAPI.js';
import GoalStatus from './GoalStatus.jsx';

const GoalForm = () => {
    const navigate = useNavigate()
    const { getGoals, goals } = useContext(GoalsContext)
    const { openAlert } = useContext(SnackbarContext)
    const { setLoading, loading } = useContext(LoaderContext)
    const [selectedDate, setSelectedDate] = useState(null);
    const [checked, setChecked] = useState(false)
    const [isCoach, setIsCoach] = useState(false)
    const user = localStorage.getItem('user')
    const [loggedUser] = useState(JSON.parse(user))
    const { peopleBuilderId } = useParams()
    const [goalId, setGoalId] = useState(useParams().goalId)
    const [userPeopleBuilder, setUserPeopleBuilder] = useState([])
    const [goalDisabled, setGoalDisabled] = useState(false)
    const [steps, setSteps] = useState([
        {
            step_order: 0,
            label: 'Step 1',
            step: ''
        }
    ])
    const [comment, setComment] = useState('')
    const [comments, setComments] = useState([])
    const [goalData, setGoalData] = useState({
        people_builder_id: '',
        goal_name: '',
        goal_term: '',
        goal_importance: '',
        goals_etro: '',
        relationship: '',
        target_date: null,
        goal_approval: '',
        goal_status: 'Draft Goal',
        checkin_status: '',
        comments_id: '',
        steps: [],
        goal_approved_date: null,
        comments: null,

    })
    const [emptyField, setEmptyField] = useState({
        'goal_name': { error: false, errorText: '' },
        'goal_term': { error: false, errorText: '' },
        'goal_importance': { error: false, errorText: '' },
        'goals_etro': { error: false, errorText: '' },
        'relationship': { error: false, errorText: '' },
        'steps': { error: false, errorText: '' },
        'target_date': { error: false, errorText: '' },
    })

    const disableGoal = (userPb) => {

        if (isCoach || loggedUser.lifecycle_admin) {
            setGoalDisabled(false)
        } else if (userPb[0]?.pb_settings.status === 'active' && goalData.goal_status !== 'Active') {
            setGoalDisabled(false)
        } else {
            setGoalDisabled(true)
        }
    }

    const updateGoalData = (key, value) => {
        setGoalData(prevData => ({ ...prevData, inheritance: false }))
        if (key === 'steps') {
            setGoalData(prevData => ({ ...prevData, steps: value }))
        } else {
            setGoalData(prevData => ({ ...prevData, [key]: value }))
        }
    }

    const hasEmptyFields = () => {
        let hasEmptyFields = false
        const fieldsToCheckWhenApproval = ['goal_name', 'goal_term', 'goal_importance', 'goals_etro', 'relationship', 'target_date', 'steps']


        if (goalData.goal_status === 'Draft Goal') {
            if (!goalData.goal_name) {
                setEmptyField(prevState => ({
                    ...prevState,
                    'goal_name': { error: true, errorText: 'Field cannot be empty' }
                }))
                return true
            } else {
                return false
            }
        } else {
            fieldsToCheckWhenApproval.forEach(field => {

                if (!goalData[field] || (Array.isArray(goalData[field]) && !goalData[field].length)) {
                    setEmptyField(prevState => ({
                        ...prevState,
                        [field]: { error: true, errorText: 'Field cannot be empty' }
                    }))
                    hasEmptyFields = true
                }
            })
        }

        return hasEmptyFields
    }

    const createGoal = async () => {
        setLoading(true)

        const dataToSave = { ...goalData, comments: JSON.stringify(comments) }

        if (hasEmptyFields()) {
            setLoading(false)
            return
        }
        const goalCreated = await createGoalAPI(dataToSave)
        if (goalCreated.status === 200) {
            setTimeout(() => {
                navigate(-1)
            }, 1500)
            openAlert('Goal created successfully', 'success')
            await getGoals()
            // if (goalCreated.data.goal_status === 'Approval') {
            //     sendEmailsGoalReadyForApproval({ ...goalCreated.data, user: userPeopleBuilder[0] })
            // }
        } else {
            openAlert(`Oops! Something went wrong: status ${goalCreated.status}`, 'error')
        }
        setLoading(false)
    }

    const getUserGoal = async () => {

        if (!goalId) {
            return
        }
        setLoading(true)
        const response = await getGoalsAPI()
        const responseUserGoals = response.filter(goal =>
            goal.id == goalId
        )
        setIsCoach(responseUserGoals[0]?.people_builder.coach_id === loggedUser.id)

        try {
            const response = await api.get(`/steps`)
            const step = response.data.filter(step => step.goal_id === responseUserGoals[0].id)
            step.length ? setSteps(step) : ''
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }

        updateGoalData('people_builder_id', responseUserGoals[0]?.people_builder_id)
        updateGoalData('goal_name', responseUserGoals[0]?.goal_name)
        updateGoalData('goal_term', responseUserGoals[0]?.goal_term)
        updateGoalData('goal_importance', responseUserGoals[0]?.goal_importance)
        updateGoalData('goals_etro', responseUserGoals[0]?.goals_etro)
        updateGoalData('inheritance', responseUserGoals[0]?.inheritance)
        updateGoalData('steps', steps)
        updateGoalData('relationship', responseUserGoals[0]?.relationship)
        updateGoalData('goal_approval', responseUserGoals[0]?.goal_approval)
        updateGoalData('target_date', responseUserGoals[0]?.target_date ? moment(responseUserGoals[0]?.target_date?.split('T')[0]) : null)
        setSelectedDate(responseUserGoals[0]?.target_date ? moment(responseUserGoals[0]?.target_date?.split('T')[0]) : null)
        setChecked(responseUserGoals[0]?.goal_approval)
        updateGoalData('goal_approved_date', responseUserGoals[0]?.goal_approved_date)
        updateGoalData('comments', JSON.parse(responseUserGoals[0]?.comments))
        setComments(JSON.parse(responseUserGoals[0]?.comments) || [])
        updateGoalData('goal_status', responseUserGoals[0]?.goal_status)

        if (responseUserGoals[0]?.goal_status === 'Needs Revision') {
            setChecked(false)
        }

    }

    const isAllGoalsActive = useCallback(async () => {
        const goalsApi = await getGoalsAPI()
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const pbId = userPeopleBuilder[0]?.id
                const goalsPB = goalsApi.filter(goal => goal.people_builder_id === pbId && goal.goal_status !== 'archived')
                const status = goalsPB.map(goal => goal.goal_status)
                const isAllActive = status.every(status => status === 'Active')
                return isAllActive ? resolve(true) : resolve(false)
            }, 4000);

        })
    }, [goals, userPeopleBuilder])

    const getUserPeopleBuilder = async () => {
        try {
            const response = await api.get(`/people-builders`)
            const responseUserPeopleBuilder = response.data.filter(pb =>
                pb.id == peopleBuilderId
            )
            setIsCoach(responseUserPeopleBuilder[0]?.coach.id === loggedUser.id)
            setUserPeopleBuilder(responseUserPeopleBuilder)
            updateGoalData('people_builder_id', responseUserPeopleBuilder[0]?.id)
        } catch (error) {
            console.log(error)
        }
    }

    const updateGoal = async (id) => {
        setLoading(true)
        if (hasEmptyFields()) {
            setLoading(false)
            return
        }
        let data = Object.fromEntries(
            Object.entries(goalData).filter(([_, v]) => {

                if (_ === 'goal_approved_date' || _ === 'comments') {
                    return true
                }

                return v !== undefined &&
                    v !== null &&
                    v !== '' &&
                    (typeof v !== 'object' || Object.keys(v).length !== 0)
            })
        )

        const dataToSave = { ...data, comments: JSON.stringify(comments) }
        const goalUpdated = await updateGoalAPI(id, dataToSave)
        if (goalUpdated.status === 200) {
            openAlert('Goal updated successfully', 'success')
            setLoading(false)
            await getUserGoal()
            setTimeout(() => {
                navigate(-1)
            }, 1000);
            if (dataToSave.goal_status === 'Active') {
                const isAllActive = await isAllGoalsActive()
                if (isAllActive) {
                    sendEmailsAddittionalInfoToPlayer(userPeopleBuilder[0])
                    sendEmailsAddittionalInfoToCoach(userPeopleBuilder[0])
                }
            } else if (dataToSave.goal_status === 'Approval') {
                // sendEmailsGoalReadyForApproval({ ...dataToSave, user: userPeopleBuilder[0] })
            } else if (dataToSave.goal_status === 'Needs Revision') {
                sendEmailsGoalNeedsRevision({ ...dataToSave, user: userPeopleBuilder[0] })
            }
        } else {
            setLoading(false)
            openAlert(`Something was wrong on update goal. Status: ${goalUpdated.status}`, 'error')

        }

    }

    const matchesSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))

    const handleAddComment = useCallback(() => {

        setComments(prevState => ([...prevState, {

            comment: comment,
            commentDate: moment().format('MMMM DD, YYYY [at] hh:mm a').replace(/\./g, ''),
            name: loggedUser.full_name,
            avatar: loggedUser.photo_url
        }]))
        updateGoalData('comments', JSON.stringify(comments))
        setComment('')
    })

    useEffect(() => {
        getUserPeopleBuilder()
        getUserGoal()
        getGoals()
    }, [])

    useEffect(() => {
        disableGoal(userPeopleBuilder)
    }, [userPeopleBuilder])

    return (

        <Container sx={{ mt: 10 }} maxWidth={matchesSmallScreen ? 'sm' : 'xl'} >
            <Stack direction={matchesSmallScreen ? 'column' : 'row'} marginRight='0'>
                <BackdropLoader openBackdrop={loading} />
                <Card sx={{ maxHeight: '85vh', overflow: 'auto', width: '100%' }}>
                    <CardContent>
                        <Stack
                            direction='row'
                            spacing={4}
                            alignItems='center'
                            alignContent='space-between'
                        >
                            <Grid item display={matchesSmallScreen ? 'none' : 'block'}>
                                <ImageList>
                                    <ImageListItem sx={{ width: '10rem' }}>
                                        <img src="https://iili.io/J1MU5vV.png" />
                                    </ImageListItem>
                                </ImageList>
                            </Grid>
                            <Box sx={{ padding: 2, width: '100%' }}>
                                <Stack
                                    direction='row'
                                    spacing={4}
                                    justifyContent='space-between'
                                    alignContent='center'
                                >
                                    <Grid item sm={12}>
                                        <Typography variant='span'>Player:
                                            <Chip label={userPeopleBuilder[0]?.player?.full_name || loggedUser.full_name} />
                                        </Typography>
                                    </Grid>
                                    <Typography variant='span'>Coach:
                                        <Chip label={userPeopleBuilder[0]?.coach?.full_name || loggedUser.coach} />
                                    </Typography>
                                    <Typography variant='span'>Year: {userPeopleBuilder[0]?.year || new Date().getFullYear()}</Typography>
                                </Stack>
                            </Box>
                        </Stack>
                        <Container>
                            <FormGroup sx={{ mt: 2 }}>
                                <Stack spacing={4}>
                                    {/* <Stack direction='row' spacing={2}> */}
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                disabled={goalDisabled}
                                                id="goal-name outlined-basic"
                                                label="Goal Name"
                                                variant='standard'
                                                value={goalData.goal_name}
                                                fullWidth
                                                onChange={(e) => {
                                                    setEmptyField({ ...emptyField, goal_name: { error: false, errorText: '' } })
                                                    updateGoalData('goal_name', e.target.value)
                                                }}
                                                helperText={emptyField.goal_name.errorText}
                                                error={emptyField.goal_name.error}
                                                required
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete
                                                fullWidth
                                                disabled={goalDisabled}
                                                options={[
                                                    'Short Term (1-3 Months)',
                                                    'Annual Term (12 Months)',
                                                    'Medium Term (1-2 Years)',
                                                    'Long Term (2-5 Years)',
                                                    'Life Long (5+ Years)'
                                                ]}
                                                label="Goal Term"
                                                variant='standard'
                                                value={goalData?.goal_term || null}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        helperText={emptyField.goal_term.errorText}
                                                        error={emptyField.goal_term.error}
                                                        required={goalData.goal_status !== 'Draft Goal' ? true : false}
                                                        label="Goal Term"
                                                        variant="standard"
                                                    />
                                                )}
                                                onChange={(e, newValue) => {
                                                    setEmptyField({ ...emptyField, goal_term: { error: false, errorText: '' } })
                                                    updateGoalData('goal_term', newValue)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <TextField
                                        disabled={goalDisabled}
                                        id="goal-important outlined-multiline-static"
                                        label="Goal Importance"
                                        multiline
                                        rows={4}
                                        variant='outlined'
                                        value={goalData.goal_importance}
                                        onChange={(e) => {
                                            setEmptyField({ ...emptyField, goal_importance: { error: false, errorText: '' } })
                                            updateGoalData('goal_importance', e.target.value)
                                        }}
                                        helperText={emptyField.goal_importance.errorText}
                                        error={emptyField.goal_importance.error}
                                        required={goalData.goal_status !== 'Draft Goal' ? true : false}
                                    />
                                    <Grid item xs={12} sm={4}>
                                        <Autocomplete
                                            fullWidth
                                            disabled={goalDisabled}
                                            value={goalData?.goals_etro || null}
                                            options={[
                                                'Culture of Accountability & High Performing Teams',
                                                'Exceptional Delivery',
                                                'High Level of Quality',
                                                'Industry Partners',
                                                'Innovation at Every Level',
                                                'Lean Process',
                                                'People First'
                                            ]}
                                            label="Goals ETRO"
                                            variant='standard'
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label="Goals ETRO"
                                                    variant="standard"
                                                    helperText={emptyField.goals_etro.errorText}
                                                    error={emptyField.goals_etro.error}
                                                    required={goalData.goal_status !== 'Draft Goal' ? true : false}
                                                />
                                            }
                                            onChange={(e, newValue) => {
                                                setEmptyField({ ...emptyField, goals_etro: { error: false, errorText: '' } })
                                                updateGoalData('goals_etro', newValue)
                                            }}

                                        />
                                    </Grid>
                                    <TextField
                                        disabled={goalDisabled}
                                        id="goal-related outlined-multiline-static"
                                        label="Expand on the relation between your Goal and the related Strategic Goal:"
                                        multiline
                                        rows={4}
                                        variant='outlined'
                                        value={goalData.relationship}
                                        onChange={(e) => {
                                            setEmptyField({ ...emptyField, relationship: { error: false, errorText: '' } })
                                            updateGoalData('relationship', e.target.value)
                                        }}
                                        helperText={emptyField.relationship.errorText}
                                        error={emptyField.relationship.error}
                                        required={goalData.goal_status !== 'Draft Goal' ? true : false}

                                    />
                                    <GoalSteps
                                        disabled={goalDisabled}
                                        setGoalId={setGoalId}
                                        goalId={goalId}
                                        setSteps={setSteps}
                                        steps={steps}
                                        onSaveSteps={updateGoalData}
                                        goalData={goalData}
                                        emptyField={emptyField}
                                        setEmptyField={setEmptyField}
                                    />
                                    <Stack spacing={4} >
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                        >
                                            <DatePicker
                                                label="Target Date"
                                                disabled={goalDisabled}
                                                sx={{ width: '100%' }}
                                                value={selectedDate}
                                                onChange={(newValue) => {
                                                    setSelectedDate(newValue)
                                                    setEmptyField({ ...emptyField, target_date: { error: false, errorText: '' } })
                                                    updateGoalData('target_date', newValue ? newValue.format().split('T')[0] : '')
                                                }}
                                                views={['year', 'month', 'day']}
                                                required
                                                slotProps={{
                                                    textField: {
                                                        helperText: emptyField.target_date.errorText,
                                                        error: emptyField.target_date.error,
                                                        required: goalData.goal_status !== 'Draft Goal' ? true : false
                                                    }
                                                }}

                                                format='YYYY-MM-DD'
                                            />
                                        </LocalizationProvider>
                                        {!isCoach && !goalDisabled ? (
                                            <GoalStatus
                                                leftStatus='Draft Goal'
                                                rightStatus='Ready for Approval'
                                                checked={checked}
                                                setChecked={setChecked}
                                                goalDisabled={goalDisabled}
                                                updateGoalData={updateGoalData}
                                            />

                                        ) :
                                            isCoach && !goalId ? (
                                                <GoalStatus
                                                    leftStatus='Draft Goal'
                                                    rightStatus='Goal Approved'
                                                    checked={checked}
                                                    setChecked={setChecked}
                                                    goalDisabled={goalDisabled}
                                                    updateGoalData={updateGoalData}

                                                />
                                            ) : (
                                                <GoalStatus
                                                    leftStatus='Back To Revision'
                                                    rightStatus='Goal Approved'
                                                    checked={checked}
                                                    setChecked={setChecked}
                                                    goalDisabled={goalDisabled}
                                                    updateGoalData={updateGoalData}
                                                />
                                            )}
                                        <CommentsTimeline
                                            setComment={setComment}
                                            comment={comment}
                                            setComments={setComments}
                                            comments={comments}
                                            loggedUser={loggedUser}
                                            handleAddComment={handleAddComment}
                                            updateGoalData={updateGoalData}
                                        // updateGoalComments={updateGoalComments}
                                        />
                                    </Stack>
                                    <Divider />
                                    <Stack direction='row' spacing={2} justifyContent='right'>
                                        <Button
                                            onClick={() => navigate(-1)}
                                            color='error'
                                            variant="contained">Cancel</Button>
                                        <Tooltip title={!isCoach && goalDisabled ? 'This goal is not update by player anymore' : ''}>
                                            <Stack>
                                                <Button
                                                    variant="contained"
                                                    // disabled={!isCoach ? goalDisabled : false}
                                                    color='success'
                                                    type='submit'
                                                    onClick={() => {
                                                        goalId ? updateGoal(goalId, goalData) : createGoal()
                                                    }}
                                                >
                                                    Save
                                                </Button>
                                            </Stack>
                                        </Tooltip>
                                    </Stack>
                                </Stack>
                            </FormGroup>
                        </Container>
                    </CardContent>
                </Card>
                <SideBarOnGoalForm />
            </Stack>
        </Container >
    )


}

export default GoalForm