import { GoogleOAuthProvider, useGoogleOneTapLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import { useCallback, useContext, useEffect, useState } from 'react';
import { EmployeesContext } from '../context/UsersContext.js';
import api from '../apis/api.js'

const GoogleAuth = () => {
    const navigate = useNavigate()
    const [token, setToken] = useState(localStorage.getItem('token'))
    const [googleUser, setGoogleUser] = useState(localStorage.getItem('user'))
    const [slackToken, setSlackToken] = useState(localStorage.getItem('slackUserData'))
    const { fetchUsers } = useContext(EmployeesContext)

    useEffect(() => {
        fetchUsers()

        if (googleUser && slackToken) {
            navigate('/employee')
        } else if (googleUser && !slackToken) {
            navigate('/slack-auth')
        } else if (!googleUser) {
            navigate('/')
        }

    }, [token, navigate, googleUser, slackToken])

    const loginWithGoogle = useCallback(async (response) => {
        const usersAdmin = ['Lisa Garrone', 'Dara Wone', 'Shannon Callender', 'Mike Maierle', 'Marcelo Rohrs', 'Dieferson Oliveira', 'Anouk Van-Bogaert', 'User Test']

        try {
            const authResponse = await api.post(`/api/auth`, response, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            const data = authResponse.data
            if (usersAdmin.includes(data.user.full_name)) {
                data.user.lifecycle_admin = true
            }
            localStorage.setItem('token', data.token)
            localStorage.setItem('user', JSON.stringify(data.user))

            if (authResponse.status === 200) {
                const slackAuth = await api.get(`/slack/${data.user.id}`)
                localStorage.setItem('slackUserData', JSON.stringify(slackAuth.data))
                setToken(data.token)
                if (!slackAuth.data.length) {
                    navigate('/slack-auth')
                } else {
                    window.location.reload()
                }
            } else {
                navigate('/not-auth')
            }

        } catch (e) {
            console.error('Error', e)
        }
    })

    return (
        <GoogleOAuthProvider clientId="769965408014-113aled7k6cf3djda7km6cd7fqhfmkl1.apps.googleusercontent.com">
            <Grid display={'none'} container justifyContent="center">
                <GoogleLogin
                    onSuccess={loginWithGoogle}
                    onError={() => console.log('Login Failed')}
                    useOneTap
                // auto_select={true}
                // cookiePolicy={'single_host_origin'}
                />
            </Grid>
        </GoogleOAuthProvider>
    )
}

export default GoogleAuth