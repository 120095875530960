import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useLocation } from 'react-router-dom';
import {
    Card, CardContent, Paper, Typography, Switch, Container, Chip, Stack,
    TextField, List, ListItem, ListItemText, Button, CardActions, Grid,
    Divider, IconButton, ImageList, ImageListItem, useMediaQuery, TableContainer,
    Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Snackbar, Alert
} from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CheckinGoals from './CheckinGoals';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { EmployeesContext } from '../context/UsersContext.js';
import api from '../apis/api.js';
import { useParams, useNavigate } from 'react-router-dom';
import BackdropLoader from './common/BackdropLoader.jsx';
import { green } from '@mui/material/colors';
import { alpha, styled } from '@mui/material/styles';
import { CheckInContext } from '../context/CheckInContexts.js';
import { SnackbarContext } from '../context/SnackbarContext.js';
import { PeopleBuilderContext } from '../context/PeopleBuilderContext.js';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const CheckInForm = () => {
    const [checked, setChecked] = React.useState(false);
    const [player, setPlayer] = useState({})
    const [selectedDate, setSelectedDate] = useState(null)
    const { employees, fetchUsers } = useContext(EmployeesContext)
    const { getCheckIns, checkIns } = useContext(CheckInContext)
    const { getPeopleBuilders, allPeopleBuilder } = useContext(PeopleBuilderContext)
    const { openAlert } = useContext(SnackbarContext)
    const location = useLocation()
    const matchesSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const { playerId, checkinId, peopleBuilderId } = useParams();
    const [userLogged] = useState(JSON.parse(localStorage.getItem('user')))
    const [isCoach, setIsCoach] = useState(false)
    const [isPlayer, setIsPlayer] = useState(false)
    const [checkinData, setCheckinData] = useState({
        monthly_ref: months[moment().month()],
        meeting_date: null,
        check_in_date: moment(),
        general_comments: null,
        status: 'active',
        creation_owner_id: userLogged.id,
        coach_id: null,
        player_id: playerId,
        check_in_type: null
    })
    const [checkin, setCheckin] = useState([])
    const navigate = useNavigate()
    const [openLoader, setOpenLoader] = useState(false)
    const [checkinGoalData, setCheckinGoalData] = useState([{
        checki_in_id: 0,
        goal_id: 0,
        check_in_goal_status: '',
        goal_comments: ''
    }])
    const [fileDescription, setFileDescription] = useState('')
    const [fileUrl, setFileUrl] = useState('')
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [descriptionRef, setDescriptionRef] = useState(null);
    const [urlRef, setUrlRef] = useState(null);
    const [params] = useState(useParams())
    const [hover, setHover] = useState(false)
    const [title, setTitle] = useState('')
    const [maxDate, setMaxDate] = useState('')
    const [minDate, setMinDate] = useState('')
    const [pbSettings, setPbSettings] = useState([])
    const [currentPbSettings, setCurrentPbSettings] = useState([])

    const getPlayer = useCallback(async () => {
        setOpenLoader(true)
        let player = playerId ? employees.find((employee) => employee.id === playerId) : employees.find((employee) => employee.id === playerId)

        if (player) {
            let coach = employees.find((employee => employee.full_name === player.coach))
            setPlayer(player)
            setIsCoach(userLogged.id === coach?.id)
            setIsPlayer(userLogged.id === player?.id)
            setOpenLoader(false)
        } else {
            let checkinSelected = checkIns?.find(checkin => checkin.id === parseInt(checkinId))
            player = employees.find((employee) => employee.id === checkinSelected?.player_id)
            let coach = employees.find((employee => employee.full_name === player?.coach))
            setPlayer(player)
            setIsCoach(userLogged.id === coach?.id)
            setIsPlayer(userLogged.id === player?.id)
            setOpenLoader(false)
        }
    }, [employees])

    const GreenSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: green[600],
            '&:hover': {
                backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: green[600],
        },
    }));
    const label = { inputProps: { 'aria-label': 'Color switch demo' } };

    const findCoachFromUserLogged = () => {
        const userLoggedData = employees.find((employee) => employee.id === userLogged.id)
        const coach = employees.find((employee) => employee.full_name === userLoggedData?.coach)
        setCheckinData((prevStates) => ({ ...prevStates, coach_id: coach?.id }))
    }

    const handleSwitchChange = (event) => {
        setChecked(event.target.checked)

        if (!event.target.checked) {
            setCheckinData((prevStates) => ({
                ...prevStates,
                meeting_date: null,
                check_in_date: moment(),
                monthly_ref: months[moment().month()]
            }))
        }
        setSelectedDate(null)
    }

    const createCheckin = async () => {
        setOpenLoader(true)
        setCheckinData((prevStates) => ({ ...prevStates, files: JSON.stringify(uploadedFiles) }))
        const checkinGoalsData = checkinGoalData.filter((goal) => goal.check_in_goal_status === '')

        if (checkinGoalsData.length) {
            openAlert('Please fill status in the goals', 'error')
            setOpenLoader(false)
            return
        }

        const data = {
            ...checkinData,
            files: JSON.stringify(uploadedFiles),
            checkinGoalData
        }

        const checkInDateValid = data.check_in_type !== 'monthly-review' ? isCheckInDateValid(data.check_in_date, minDate, maxDate) : true
        if (!checkInDateValid) {
            openAlert(`Check-in Date should be between ${minDate} and ${maxDate}`, 'warning')
            setOpenLoader(false)
            return
        }

        try {
            const response = await api.post('/checkins', data)

            if (response.status === 201) {
                openAlert('Check-in created successfully', 'success')

                if (isCoach) {
                    navigate(`/team-people-builder/${playerId}`)
                } else if (isPlayer) {
                    navigate(`/personal-people-builder/${playerId}`)
                } else {
                    navigate(`/company-people-builder/${playerId}`)
                }
            } else {
                openAlert(`Oops! Something went wrong! Status: ${response.status} `, 'error')
            }

            setOpenLoader(false)
        } catch (error) {
            console.log(error)
            openAlert('Error creating check-in', 'error')
            setOpenLoader(false)
        }
    }

    const updateCheckin = async () => {
        setOpenLoader(true)
        setCheckinData((prevStates) => ({ ...prevStates, files: JSON.stringify(uploadedFiles) }))
        const data = {
            ...checkinData,
            files: JSON.stringify(uploadedFiles),
            checkinGoalData
        }

        const checkInDateValid = data.check_in_type !== 'monthly-review' ? isCheckInDateValid(data.check_in_date, minDate, maxDate) : true
        if (!checkInDateValid) {
            openAlert(`Check-in Date should be between ${minDate} and ${maxDate}`, 'warning')
            setOpenLoader(false)
            return
        }

        try {
            const response = await api.put(`/checkins/${checkinId}`, data)

            if (response.status === 200) {
                openAlert('Check-in updated successfully', 'success')

                if (isCoach) {
                    navigate(`/team-people-builder/${player.id}`)
                } else if (isPlayer) {
                    navigate(`/personal-people-builder/${player.id}`)
                } else {
                    navigate(`/company-people-builder/${player.id}`)
                }

            }

            setOpenLoader(false)
        } catch (error) {
            console.log(error)
            openAlert('Error updating check-in', 'error')
            setOpenLoader(false)
        }
    }

    const isCheckInDateValid = (checkInDate, minDate, maxDate) => {
        const checkIn = new Date(checkInDate);
        const min = new Date(minDate);
        const max = new Date(maxDate);

        return checkIn >= min && checkIn <= max;
    };

    const uploadFile = async () => {

        if (!urlRef.value.includes('http://') && !urlRef.value.includes('https://')) {
            openAlert('Wrong URL', 'error')
            setFileUrl('')
            urlRef?.focus()
            return;
        }

        if (!fileDescription.trim()) {
            descriptionRef?.focus();
            return;
        }
        if (!fileUrl.trim()) {
            urlRef?.focus();
            return;
        }

        setUploadedFiles([...uploadedFiles, { id: uploadedFiles.length + 1, description: fileDescription, url: fileUrl, uploaded_at: new Date().toISOString().split('T')[0] }]);
        setFileDescription('');
        setFileUrl('');
    }

    const getCheckin = useCallback(() => {
        setOpenLoader(true)
        const checkin = checkIns?.find((checkin) => checkin.id === parseInt(checkinId))

        if (checkin) {
            setUploadedFiles(JSON.parse(checkin.files))
            const player = employees.find((employee) => employee.id === checkin.player_id)
            setPlayer(player)
            setCheckin(checkin)
            setCheckinData(checkin)
            checkin.meeting_date ? setChecked(true) : setChecked(false)
            setSelectedDate(moment(checkin?.meeting_date))
        }
        setOpenLoader(false)
    }, [checkIns])

    const getPbSettings = async () => {
        try {
            const response = await api.get('/people-builder-settings')
            const data = response.data
            setPbSettings(data)
        } catch (error) {
            console.log('error', error)
        }
    }

    const removeUploadedFile = (fileToRemove) => {
        setUploadedFiles(prevFiles => prevFiles.filter(file => file.id !== fileToRemove));
    };

    useEffect(() => {
        setOpenLoader(true)
        getTitleToCheckinForm()
        const peopleBuilderFiltered = allPeopleBuilder.find((pb) => pb.id === parseInt(peopleBuilderId))
        const currentSettings = pbSettings.find((setting) => setting.id === peopleBuilderFiltered?.pb_settings_id)

        setCurrentPbSettings(currentSettings)
        const year = moment(currentSettings?.start_date).year()

        if (location.pathname.includes('mid')) {
            setCheckinData((prevStates) => ({
                ...prevStates,
                check_in_type: 'mid-year-review'
            }))
            const month = moment(currentSettings?.mid_year_checkin.split('T')[0]).month()
            setMinDate(`${new Date(year, month, 1).toLocaleDateString('en-ca')}`)
            setMaxDate(`${new Date(year, month + 1, 0).toLocaleDateString('en-ca')}`)
        } else if (location.pathname.includes('end')) {
            setCheckinData((prevStates) => ({
                ...prevStates,
                check_in_type: 'end-year-review'
            }))
            const month = moment(currentSettings?.end_year_checkin.split('T')[0]).month()
            setMinDate(`${new Date(year, month, 1).toLocaleDateString('en-ca')}`)
            setMaxDate(`${new Date(year, month + 1, 0).toLocaleDateString('en-ca')}`)
        } else {
            setCheckinData((prevStates) => ({
                ...prevStates,
                check_in_type: 'monthly-review'
            }))
        }
        setOpenLoader(false)
    }, [pbSettings])

    const getTitleToCheckinForm = () => {
        const path = location.pathname

        if (path.includes('mid')) {
            setTitle('Mid-Year Review')
        } else if (path.includes('end')) {
            setTitle('End-Year Review')
        } else {
            setTitle('Monthly 1:1')
        }
    }

    useEffect(() => {

        findCoachFromUserLogged()
        getPlayer()
        getCheckin()
    }, [employees, checkinId])

    useEffect(() => {
        getPbSettings()
        fetchUsers()
        getPeopleBuilders()
        getPlayer()
        getCheckIns()
    }, [])

    useEffect(() => {
        getCheckin()
    }, [checkIns])

    return (
        <Container sx={{ mt: 10 }} maxWidth='xl' >
            <BackdropLoader openBackdrop={openLoader} />
            <Card sx={{ mt: 4, maxHeight: '80vh', overflow: 'auto' }}>
                <CardContent>
                    <Paper elevation={2} sx={{ mb: 2, p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Grid item display={matchesSmallScreen ? 'none' : 'block'}>
                            <ImageList>
                                <ImageListItem sx={{ width: '8rem', margin: 0 }}>
                                    <img src="https://iili.io/J1MU5vV.png" />
                                </ImageListItem>
                            </ImageList>
                        </Grid>
                        <Typography
                            sx={{ width: '20%', fontSize: 14, fontWeight: 'bold' }}
                            variant='span'>Player: <Chip label={player?.full_name} /> </Typography>
                        <Typography
                            sx={{ width: '20%', fontSize: 14, fontWeight: 'bold' }}
                            variant='span'>Coach: <Chip label={player?.coach} /> </Typography>
                        <Typography
                            sx={{ width: '20%', fontSize: 14, fontWeight: 'bold' }}
                            variant='span'>{new Date().toLocaleDateString('en-ca')}</Typography>
                    </Paper>
                    <Typography align='center' variant='h5'>{title}</Typography>
                    <Paper elevation={2} sx={{ mt: 2, mb: 2, p: 2, minHeight: 90 }}>
                        <Stack direction='row' spacing={2} alignItems='center'>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} variant='span'>In Person Meeting:</Typography>
                                {/* <Chip color={!checked ? 'error' : 'default'} sx={{ ml: 2, mr: 2, cursor: 'pointer' }} label='No' /> */}
                                <GreenSwitch
                                    {...label}
                                    checked={checked}
                                    onChange={handleSwitchChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                {/* <Chip color={checked ? 'success' : 'default'} sx={{ ml: 2, cursor: 'pointer', mr: 2 }} label='Yes' /> */}
                            </Stack>
                            <LocalizationProvider
                                dateAdapter={AdapterMoment}
                            >
                                <DatePicker
                                    label="Meeting Date"
                                    maxDate={moment(maxDate)}
                                    minDate={moment(minDate)}
                                    sx={{ visibility: checked ? 'visible' : 'hidden', width: '25%' }}
                                    value={selectedDate}
                                    onChange={(newValue) => {
                                        setSelectedDate(newValue)
                                        setCheckinData((prevStates) => ({
                                            ...prevStates,
                                            meeting_date: newValue,
                                            check_in_date: newValue,
                                            monthly_ref: months[newValue.month()]
                                        }))
                                        // getCheckinType(newValue)
                                    }}
                                    format='YYYY-MM-DD'
                                    views={['year', 'month', 'day']}
                                    slotProps={{ textField: { fullWidth: true } }}
                                />
                            </LocalizationProvider>
                        </Stack>
                    </Paper>
                    <Paper>
                        <CheckinGoals
                            currentPbSettings={currentPbSettings}
                            checkinGoalData={checkinGoalData}
                            setCheckinGoalData={setCheckinGoalData}
                        />
                    </Paper>
                    <TextField
                        id="outlined-multiline-static"
                        label="General Comments"
                        multiline
                        fullWidth
                        value={checkinData.general_comments || ''}
                        sx={{ mt: 2 }}
                        rows={4}
                        variant='outlined'
                        onChange={(e) => setCheckinData((prevStates) => ({ ...prevStates, general_comments: e.target.value }))}
                    />

                    <Tooltip followCursor title='Click to view Coach & Player Prompts'>
                        <Paper
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                            onClick={() => window.open('https://drive.google.com/file/d/1UqHwLlEbPx7eOZ1A7Jb2qTPPhYMV-ebK/view', '_blank')}
                            elevation={4}
                            sx={{ mt: 2, p: 2, cursor: 'pointer' }}
                        >
                            <Typography textAlign='center' variant="h5" color="initial">Coach & Player Prompts - 1:1 Check in</Typography>
                            <Stack direction='row' spacing={2}>
                                <Stack direction='row' spacing={2}>
                                    <List>
                                        <Typography variant="h6" color="initial">Coach Prompts:</Typography>
                                        <ListItem sx={{ fontSize: 10 }}>
                                            <Stack>
                                                <ListItemText
                                                    primaryTypographyProps={{ fontSize: 14 }}
                                                    primary="What part of your day/work makes the day go by fast?" />
                                                <ListItemText
                                                    primaryTypographyProps={{ fontSize: 14 }}
                                                    primary="What part of your day/work makes the day go by slowly?" />
                                                <ListItemText
                                                    primaryTypographyProps={{ fontSize: 14 }}
                                                    primary="As your coach, what can I do to offer more guidance in your goals?" />
                                                <ListItemText
                                                    primaryTypographyProps={{ fontSize: 14 }}
                                                    primary="As your coach, what am I doing well?" />
                                                <ListItemText
                                                    primaryTypographyProps={{ fontSize: 14 }}
                                                    primary="What are some things you are proud of accomplishing or being part of recently?
                                    " />
                                                <ListItemText
                                                    primaryTypographyProps={{ fontSize: 14 }}
                                                    primary="At our last check in, you said ____ was a challenge. How is that going for you
                                        now?" />
                                            </Stack>
                                        </ListItem>
                                    </List>
                                </Stack>
                                <Stack>
                                    <List>
                                        <Typography variant="h6" color="initial">Player Prompts:</Typography>
                                        <ListItem>
                                            <Stack>
                                                <ListItemText
                                                    primaryTypographyProps={{ fontSize: 14 }}
                                                    primary="If I could ask for help on one goal, it would be…" />
                                                <ListItemText
                                                    primaryTypographyProps={{ fontSize: 14 }}
                                                    primary="I really enjoy when I'm given the responsibility or opportunity to ___
                                        because____." />
                                                <ListItemText
                                                    primaryTypographyProps={{ fontSize: 14 }}
                                                    primary="I find I struggle when I'm given the responsibility or opportunity to____
                                        because____.
                                    " />
                                                <ListItemText
                                                    primaryTypographyProps={{ fontSize: 14 }}
                                                    primary="I'd like to commit to ____ to move forward in my goals before we meet again.
                                    " />
                                                <ListItemText
                                                    primaryTypographyProps={{ fontSize: 14 }}
                                                    primary="I'm really proud of accomplishing ___ since we met last." />
                                                <ListItemText
                                                    primaryTypographyProps={{ fontSize: 14 }}
                                                    primary="After learning about my Clifton Strengths, I'd like to utilize my ___ more at
                                        work. Some
                                        ideas I have are ____.
                                    " />
                                            </Stack>
                                        </ListItem>
                                    </List>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Tooltip>
                    <Stack direction='row' spacing={2} sx={{ mt: 2, mb: 2 }}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Description"
                            value={fileDescription}
                            sx={{ mt: 2, width: '40%' }}
                            variant='outlined'
                            onChange={(e) => setFileDescription(e.target.value)}
                            inputRef={setDescriptionRef}
                        />
                        <TextField
                            id="outlined-multiline-static"
                            label="URL"
                            value={fileUrl}
                            fullWidth
                            sx={{ mt: 2, width: '60%' }}
                            variant='outlined'
                            onChange={(e) => setFileUrl(e.target.value)}
                            inputRef={setUrlRef}
                        />
                        <Button onClick={uploadFile} variant='outlined'>Upload</Button>
                    </Stack>
                    <Container>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Uploaded At</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {uploadedFiles?.map((file) => (
                                        <TableRow
                                            hover
                                            key={file.description}
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                if (file.url.includes('http://') || file.url.includes('https://')) {
                                                    window.open(file.url, '_blank')
                                                } else {
                                                    openAlert('Wrong URL', 'error')
                                                }
                                            }}
                                        >
                                            <TableCell>{file.description}</TableCell>
                                            <TableCell>{file.uploaded_at}</TableCell>
                                            <TableCell>
                                                <Tooltip title="Delete File">
                                                    <IconButton
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            removeUploadedFile(file.id)
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>
                    <Divider />
                </CardContent>
                <CardActions sx={{ justifyContent: 'end', mb: 2 }}>
                    <Button color='error' variant='contained' onClick={() => {
                        navigate(-1)
                    }}>Cancel</Button>
                    <Button color='success' variant='contained' onClick={params.checkinId ? updateCheckin : createCheckin}>Save</Button>
                </CardActions>

            </Card>

        </Container>
    )
}

export default CheckInForm