import React, { useCallback, useEffect, useState } from 'react'
import { Card, CardContent, Stack, Typography, Avatar, Chip } from '@mui/material'
import styles from '../css/Profile-page.module.css'
import Strengths from './Strengths.jsx';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import api from '../apis/api.js'

const EmployeeInfo = ({ employee, setOpenChangePhoto }) => {
    const [showAddPhotoIcon, setShowAddPhotoIcon] = useState(false);
    const [photoOpacity, setPhotoOpacity] = useState(1);
    const [photo, setPhoto] = useState(employee.photo_url)
    const userLocal = localStorage.getItem('user');
    const userObject = JSON.parse(userLocal);
    const isAdmin = userObject.lifecycle_admin;

    const handleClickOpenPhoto = () => {
        setOpenChangePhoto(true);
    }

    const newIconForSuspendedUser = useCallback(() => {
        if (employee.suspended) {
            return <PersonOffIcon sx={{ fontSize: '40px' }} />
        }
    }, [employee.suspended])

    const userPhoto = async () => {
        if (!employee?.id) return
        try {
            const response = await api.get(`/user-photo/${employee.id}`);
            const responsePhoto = response.data.photoData
            const photo = responsePhoto
                .replace(/_/g, '/')
                .replace(/-/g, '+')
                .replace(/\./g, '=');
            setPhoto(`data:${response.data.mimeType};base64,${photo}`)
            return response.data
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        userPhoto()
    }, [employee])

    return (
        <Card>
            <CardContent>
                <Stack sx={{ 'alignItems': 'center' }} direction="row" spacing={2}>
                    <Stack className={styles.withTotal} direction="row" justifyContent='space-between' alignItems='center' spacing={2}>
                        <Stack direction='row' sx={{ alignItems: 'center' }}>
                            <Stack direction="row">
                                {employee.suspended ? newIconForSuspendedUser() : (
                                    <Avatar
                                        src={photo}
                                        sx={{ width: 56, height: 56, opacity: photoOpacity }}
                                        onMouseEnter={() => {
                                            setShowAddPhotoIcon(true)
                                            setPhotoOpacity(0.5)
                                        }}
                                        onMouseLeave={() => {
                                            setShowAddPhotoIcon(false)
                                            setPhotoOpacity(1)
                                        }}
                                    />
                                )}
                                {showAddPhotoIcon && isAdmin && (
                                    <AddAPhotoIcon
                                        style={{ margin: '0.4rem', cursor: 'pointer', fontSize: 40, position: 'absolute' }}
                                        onMouseEnter={() => {
                                            setShowAddPhotoIcon(true)
                                            setPhotoOpacity(0.5)
                                        }}
                                        onMouseLeave={() => {
                                            setShowAddPhotoIcon(false)
                                            setPhotoOpacity(1)
                                        }}
                                        onClick={handleClickOpenPhoto}
                                    >
                                        <AddAPhotoIcon style={{ opacity: 0.5 }} />
                                    </AddAPhotoIcon>
                                )}
                            </Stack>
                            <Stack spacing={2}>
                                <Stack direction='row' spacing={1}>
                                    <Typography className={styles.name_employee} variant="subtitle2" gutterBottom>{employee?.full_name}</Typography>
                                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        {(employee.lifecycle_admin) && (
                                            <img style={{ alignContent: 'center', width: '20px' }} src='https://iili.io/Jazab4a.png' />
                                        )}
                                    </div>
                                </Stack>
                                <Typography className={styles.name_employee} variant="subtitle2" gutterBottom>{employee?.email}</Typography>
                            </Stack>
                            <Stack sx={{ ml: 2 }} spacing={1}>
                                <Chip
                                    sx={{ backgroundColor: '#ffeb3b' }}
                                    label={employee.etro_team ? 'ETRO Team' : 'Not ETRO Team'}
                                />
                            </Stack>
                        </Stack>
                        <Strengths employeeId={employee.id} />
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default EmployeeInfo