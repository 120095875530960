import React from 'react';
import { IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import RoutesComponent from './components/RoutesComponent';
import { SlackProvider } from './context/SlackUserInfoContext.js';
import { EmployeesProvider } from './context/UsersContext.js';
import { PeopleBuilderProvider } from './context/PeopleBuilderContext.js';
import { GoalsProvider } from './context/GoalsContext.js';
import { CheckInProvider } from './context/CheckInContexts.js';
import SnackbarAlert from './components/SnackbarAlert';
import { OrgUnitPathsProvider } from './context/OrgUnitPathsContext.js';
import LoaderProvider from './context/LoaderContext.js';
import { StrengthsProvider } from './context/StrengthsContext.js';
import { EmployeeFiltersProvider } from './context/EmployeeFiltersContext.js';

const App = () => {

    // const handleClick = () => {
    //     localStorage.clear();
    //     window.location.reload();
    // }

    {/* <IconButton sx={{color:'white', position: 'absolute', bottom: '0px', left:'10px'}} onClick={handleClick}>
        <LogoutIcon  />
    </IconButton> */}

    return (
        <>
            <SnackbarAlert />
            <LoaderProvider>
                <StrengthsProvider>
                    <OrgUnitPathsProvider >
                        <CheckInProvider>
                            <GoalsProvider>
                                <PeopleBuilderProvider>
                                    <SlackProvider>
                                        <EmployeesProvider>
                                            <EmployeeFiltersProvider>
                                                <RoutesComponent />
                                            </EmployeeFiltersProvider>
                                        </EmployeesProvider>
                                    </SlackProvider>
                                </PeopleBuilderProvider>
                            </GoalsProvider>
                        </CheckInProvider>
                    </OrgUnitPathsProvider>
                </StrengthsProvider>
            </LoaderProvider>
        </>
    );
}

export default App;